// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { PageNotFound } from "../../svg";
import { FormattedMessage, useIntl } from "react-intl";
// data stubs
import theme from "../../data/theme";

function SitePageNotFound() {
    const intl = useIntl();
    return (
        <div className="block">
            <Helmet>
                <title>{`${intl.formatMessage({ id: '404_page', defaultMessage: "404 Page Not Found" })} — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="not-found">
                    <div className="not-found__404">
                        <PageNotFound />
                    </div>
                    <div className="not-found__content">
                        <h1 className="not-found__title"><FormattedMessage id="404_stat_1" defaultMessage="Page Not Found" /></h1>

                        <p className="not-found__text"><FormattedMessage id="404_stat_2" defaultMessage="We can&apos;t seem to find the page you&apos;re looking for." /></p>
                        <br />
                        <Link to="/" className="btn btn-primary">
                            <FormattedMessage id="404_stat_3" defaultMessage="Go To Home Page" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageNotFound;
