// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import StroykaSlick from "../shared/StroykaSlick";


// application
import departmentsAria from "../../services/departmentsArea";
import languages from "../../i18n";
import { useWindowSize } from "../../services/utils";

export const SlickArrowLeft = ({ className, style, onClick }) => {
    const windowSize = useWindowSize()
    return (
        <div
            className={className}
            style={{ ...style, display: windowSize.width < 576 ? "none" : "block" }}
            onClick={onClick}
        />
    );
}
export const SlickArrowRight = ({ className, style, onClick }) => {
    const windowSize = useWindowSize()
    return (
        <div
            className={className}
            style={{ ...style, display: windowSize.width < 576 ? "none" : "block" }}
            onClick={onClick}
        />
    );
}


const slickSettings = {
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft currentSlide={undefined} slideCount={undefined} />,
    nextArrow: <SlickArrowRight currentSlide={undefined} slideCount={undefined} />,
};

class BlockImageSlideShow extends Component {
    render() {
        const { locale, images } = this.props;
        const { direction } = languages[locale];

        const blockClasses = classNames("block-slideshow block");
        const layoutClasses = classNames("col-12");

        // Recipe Image Slides
        const imageSlides =
            images?.length > 0 ? (
                images.map((image, index) => (
                    <div key={index} className="block-slideshow__slide">
                        <img
                            className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                            src={`${image.media_url}`}
                            alt={`Recipe ${index + 1}`}
                            style={{
                                maxHeight: "1000px",
                            }}
                        />
                        <div className="video-title"><h6><FormattedMessage id={image.title} /></h6></div>
                    </div>
                ))
            ) : (
                <div className="block-slideshow__slide">
                    <img
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        src={"/images/no_image_available.jpeg"}
                        alt={"No Recipe Available"}
                    />
                </div>
            );

        return images ? (
            <div className={"container"}>


                <div className={blockClasses}>
                    <div className="row no-gutters">
                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                <StroykaSlick {...slickSettings}>{imageSlides}</StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    }
}

BlockImageSlideShow.propTypes = {
    locale: PropTypes.string,
    images: PropTypes.array, // Array of images
};

BlockImageSlideShow.defaultProps = {
    images: [], // Default to an empty array
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockImageSlideShow);
