// react
import React, { useCallback, useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

// application
import ProductCard from "../shared/ProductCard";
import { Filters16Svg, LayoutGrid16x16Svg, LayoutList16x16Svg } from "../../svg";
import { sidebarOpen } from "../../store/sidebar";
import InfiniteScroll from "react-infinite-scroll-component";
import BlockNoResult from "../blocks/BlockNoResult";
const perPage = 25;
function useSetOption(option, filter, dispatch) {
    const callback = useCallback(filter, []);
    return useCallback(
        (data) => {
            dispatch({
                type: "SET_OPTION_VALUE",
                option,
                value: callback(data),
            });
        },
        [option, callback, dispatch]
    );
}

function ProductsView(props) {
    const {
        isLoading,
        productsList,
        options,
        filters,
        dispatch,
        layout: propsLayout,
        grid,
        offcanvas,
        sidebarOpen,
        popPageHistory,
        doneLoad,
        noFilter,
        noSort,
    } = props;
    const [layout, setLayout] = useState(propsLayout);
    const intl = useIntl();

    const handleLoadMore = () => {
        let range = productsList.length > 0 ?(perPage * Math.ceil(productsList.length / perPage)) + 1 + "," + productsList.length : "0," + perPage;
        dispatch({
            type: "SET_OPTION_VALUE",
            option: "limit",
            value: range,
        });
    };
    const handleSortChange = useSetOption("sort", (event) => event.target.value, dispatch);

    const handleResetFilters = useCallback(() => {
        dispatch({ type: "RESET_FILTERS" });
    }, [dispatch]);

    const filtersCount = Object.keys(filters)
        .map((x) => filters[x])
        .filter((x) => x).length;
    let viewModes = [
        { key: "grid", title: "Grid", icon: <LayoutGrid16x16Svg /> },
        // { key: 'grid-with-features', title: 'Grid With Features', icon: <LayoutGridWithDetails16x16Svg /> },
        { key: "list", title: "List", icon: <LayoutList16x16Svg /> },
    ];

    viewModes = viewModes.map((viewMode) => {
        const className = classNames("layout-switcher__button", {
            "layout-switcher__button--active": layout === viewMode.key,
        });

        return (
            <button key={viewMode.key} title={viewMode.title} type="button" className={className} onClick={() => setLayout(viewMode.key)}>
                {viewMode.icon}
            </button>
        );
    });

    const productsListItems = productsList?.map((product) => (
        <div key={product.product_code} className="products-list__item">
            <ProductCard product={product} page={options.page} />
        </div>
    ));

    const rootClasses = classNames("products-view", {
        "products-view--loading": isLoading,
    });

    const viewOptionsClasses = classNames("view-options", {
        "view-options--offcanvas--always": offcanvas === "always",
        "view-options--offcanvas--mobile": offcanvas === "mobile",
    });

    let content;
    if (productsListItems?.length > 0) {
        content = (
            <InfiniteScroll dataLength={productsListItems.length} next={() => handleLoadMore(options.limit)} hasMore={!doneLoad}>
                <div className="products-view__content">
                    {noFilter && noSort ? null : (
                        <div className="products-view__options">
                            <div className={viewOptionsClasses}>
                                {window.location.pathname !== "/search-products" && !noFilter ? (
                                    <div className="view-options__filters-button">
                                        <button type="button" className="filters-button" onClick={() => sidebarOpen()}>
                                            <Filters16Svg className="filters-button__icon" />
                                            <span className="filters-button__title">
                                                <FormattedMessage id="filter" defaultMessage="Filters" />
                                            </span>
                                        </button>
                                    </div>
                                ) : null}

                                {noSort ? null : (
                                    <div className="view-options__control">
                                        <label htmlFor="view-options-sort">
                                            <FormattedMessage id="sort_by" defaultMessage="Sort By" />{" "}
                                        </label>
                                        <div>
                                            <select
                                                id="view-options-sort"
                                                className="form-control form-control-sm"
                                                value={options.sort}
                                                onChange={handleSortChange}
                                            >
                                                <option value="product_topsales">
                                                    {intl.formatMessage({ id: "top_sales", defaultMessage: "Top Sales" })}
                                                </option>
                                                <option value="latest">
                                                    {intl.formatMessage({ id: "latest", defaultMessage: "Latest" })}
                                                </option>
                                                <option value="price_low">
                                                    {intl.formatMessage({ id: "price_low_high", defaultMessage: "Price (Low-High)" })}
                                                </option>
                                                <option value="price_high">
                                                    {intl.formatMessage({ id: "price_high_low", defaultMessage: "Price (High-Low)" })}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        className="products-view__list products-list"
                        data-layout={layout !== "list" ? grid : layout}
                        data-with-features={layout === "grid-with-features" ? "true" : "false"}
                    >
                        <div className="products-list__body">{productsListItems}</div>
                    </div>
                </div>
            </InfiniteScroll>
        );
    } else {
        content = (
            <div>
                <div className={viewOptionsClasses}>
                    {window.location.pathname !== "/search-products" && !noFilter ? (
                        <div className="view-options__filters-button mb-3">
                            <button type="button" className="filters-button" onClick={() => sidebarOpen()}>
                                <Filters16Svg className="filters-button__icon" />
                                <span className="filters-button__title">
                                    <FormattedMessage id="filter" defaultMessage="Filters" />
                                </span>
                                {/* {!!filtersCount && (
                                <span className="filters-button__counter">{filtersCount}</span>
                            )} */}
                            </button>
                        </div>
                    ) : null}
                </div>
                <div className="products-view__empty">
                    <BlockNoResult message={<FormattedMessage id="no_item_msg" defaultMessage="No items found" />} />
                    {filters.min_price || filters.max_price ? (
                        <>
                            <div className="products-view__empty-subtitle">Try resetting the filters</div>
                            <button type="button" className="btn btn-primary btn-sm" onClick={handleResetFilters}>
                                <FormattedMessage id="reset_filter" defaultMessage="Reset filters" />
                            </button>
                        </>
                    ) : null}
                </div>
            </div>
        );
    }

    return (
        <div className={rootClasses}>
            <div className="products-view__loader" />
            {content}
        </div>
    );
}

ProductsView.propTypes = {
    /**
     * Indicates that products is loading.
     */
    isLoading: PropTypes.bool,
    /**
     * ProductsList object.
     */
    productsList: PropTypes.array,
    /**
     * Products list options.
     */
    options: PropTypes.object,
    /**
     * Products list filters.
     */
    filters: PropTypes.object,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(["grid-3-sidebar", "grid-4-full", "grid-5-full"]),
    /**
     * indicates when sidebar should be off canvas
     */
    offcanvas: PropTypes.oneOf(["always", "mobile"]),
};

ProductsView.defaultProps = {
    layout: "grid",
    grid: "grid-3-sidebar",
    offcanvas: "mobile",
    noFilter: false,
};

const mapDispatchToProps = {
    sidebarOpen,
};

export default connect(() => ({}), mapDispatchToProps)(ProductsView);
