// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
import { cartAddItem } from "../../store/cart";
import { Wishlist16Svg, Compare16Svg } from "../../svg";
import { wishlistAddItem, wishlistRemoveItem } from "../../store/wishlist";
import { getMemberCode } from "../../api/auth";
import { addCart, removeCart } from "../../api/cart";
import { debounce } from "lodash";
import Rating from "react-rating";
import store from "../../store";
import { decodeChineseText } from "../../services/utils";

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 0,
            isFavorite: props.product.in_favorite,
            ratings: 0,
        };
    }

    componentDidMount() {
        const { product, cart, reviews } = this.props;
        this.setState({
            quantity: parseInt(cart?.items?.find((item) => item.product_code == product.product_code)?.product_quantity),
            ratings: reviews?.rating,
        });
    }

    inputQtyChange = (value) => {
        this.setState({
            quantity: value,
        });
        this.debounceQtyChange();
    };

    debounceQtyChange = debounce(() => {
        this.handleChangeQuantity();
    }, 300);

    handleChangeQuantity = () => {
        let quantity = this.state.quantity;
        const { product, cart } = this.props;
        if (quantity == 0) {
            removeCart({ product_code: product.product_code });
        }
        let oriQty = parseInt(cart?.items?.find((item) => item.product_code == product.product_code)?.product_quantity);
        addCart(product, quantity - (oriQty ? oriQty : 0), this.props.history);
    };

    render() {
        const { product, layout, wishlistAddItem, wishlistRemoveItem, cart } = this.props;
        const { quantity, isFavorite, ratings } = this.state;
        let prices;
        // let qty = parseInt(cart.items?.find((item) => item.product_code == product.product_code)?.product_quantity);

        // prices = <Currency value={product.product_price} />;
        prices = (
            <div>
            {!product.final_price || parseFloat(product.product_price) <= parseFloat(product.final_price) ? (
                    <Currency value={product.product_price} />
                ) : (
                    <>
                        <span className="product__new-price">
                            <Currency value={product.final_price} />
                        </span>
                        <span className="product__old-price">
                            <Currency value={product.product_price} />
                        </span>
                    </>
                )}
            </div>
        );
        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    <ProductGallery layout={layout} images={product.product_picture} />

                    <div className="product__info">
                        <h1 className="product__name">{decodeChineseText(product.product_description)}</h1>
                        <div className="product__rating-stars">
                            <Rating
                                readonly
                                initialRating={ratings}
                                className="rating-star"
                                emptySymbol={"far fa-star"}
                                fullSymbol="fas fa-star"
                            />
                        </div>

                        {store.getState().user.deliveryMethod?.store?.store_code && getMemberCode() ? (
                            <ul className="product__meta">
                                <li className="product__meta-availability">
                                    <FormattedMessage id="availability" defaultMessage="Availability" />:{" "}
                                    {product.product_has_balance == 0 ? (
                                        <span className="text-danger">
                                            <FormattedMessage id="out_stock" defaultMessage="Out Of Stock" />
                                        </span>
                                    ) : (
                                        <span className="text-success">
                                            <FormattedMessage id="in_stock" defaultMessage="In Stock" />
                                        </span>
                                    )}
                                </li>
                            </ul>
                        ) : null}
                    </div>

                    <div className="product__sidebar">
                        <div className="product__prices">{prices}</div>
                        {getMemberCode() ? (
                            <form className="product__options">
                                <div className="form-group product__option">
                                    {product.product_has_balance == 1 ? (
                                        <label htmlFor="product-quantity" className="product__option-label">
                                            <FormattedMessage id="quantity" defaultMessage="Quantity" />
                                        </label>
                                    ) : null}

                                    <div className="product__actions">
                                        {product.product_has_balance == 1 ? (
                                            <>
                                                <div className="product__actions-item">
                                                    <InputNumber
                                                        id="product-quantity"
                                                        aria-label="Quantity"
                                                        className="product__quantity"
                                                        size="lg"
                                                        min={0}
                                                        value={quantity ? quantity : 0}
                                                        onChange={(val) => this.inputQtyChange(val)}
                                                    />
                                                </div>
                                            </>
                                        ) : null}
                                        {getMemberCode() && product?.hasOwnProperty("in_favorite") ? (
                                            <div className="product__actions-item product__actions-item--wishlist">
                                                <AsyncAction
                                                    action={
                                                        isFavorite == 1
                                                            ? () => {
                                                                  this.setState({ isFavorite: 0 });
                                                                  return wishlistRemoveItem(product);
                                                              }
                                                            : () => {
                                                                  this.setState({ isFavorite: 1 });
                                                                  return wishlistAddItem(product);
                                                              }
                                                    }
                                                    render={({ run, loading }) => (
                                                        <button
                                                            type="button"
                                                            data-toggle="tooltip"
                                                            title="Wishlist"
                                                            onClick={run}
                                                            className={classNames("btn btn-secondary btn-svg-icon btn-lg", {
                                                                "btn-loading": loading,
                                                            })}
                                                        >
                                                            <Wishlist16Svg style={isFavorite == 1 ? { fill: "#ff424f" } : {}} />
                                                        </button>
                                                    )}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </form>
                        ) : null}
                    </div>

                    {/* <div className="product__footer">
                        <div className="product__tags tags">
                            <div className="tags__list">
                                <Link to="/">Mounts</Link>
                                <Link to="/">Electrodes</Link>
                                <Link to="/">Chainsaws</Link>
                            </div>
                        </div>

                        <div className="product__share-links share-links">
                            <ul className="share-links__list">
                                <li className="share-links__item share-links__item--type--like"><Link to="/">Like</Link></li>
                                <li className="share-links__item share-links__item--type--tweet"><Link to="/">Tweet</Link></li>
                                <li className="share-links__item share-links__item--type--pin"><Link to="/">Pin It</Link></li>
                                <li className="share-links__item share-links__item--type--counter"><Link to="/">4K</Link></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    wishlistRemoveItem,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product));
