import { toast } from "react-toastify";
import { addFavoriteProduct, deleteFavoriteProduct } from "../../api/favorite";
import { decodeChineseText } from "../../services/utils";
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM, SYNC_WISHLIST } from "./wishlistActionTypes";

export function wishlistAddItemSuccess(product) {
    toast.success(`"${decodeChineseText(product.product_description)}" added to favourites`, { autoClose: 3000 });

    return {
        type: WISHLIST_ADD_ITEM,
        product,
    };
}

export function wishlistRemoveItemSuccess(product) {
    return {
        type: WISHLIST_REMOVE_ITEM,
        product,
    };
}

export function wishlistAddItem(product) {
    return (dispatch) =>
        new Promise((resolve) => {
            addFavoriteProduct([{ product_code: product.product_code }]).then((res) => {
                if (res.Status == 1) {
                    dispatch(wishlistAddItemSuccess(product));
                    resolve();
                }
            });
        });
}

export function wishlistRemoveItem(product) {
    return (dispatch) =>
        new Promise((resolve) => {
            deleteFavoriteProduct([{ product_code: product.product_code }]).then((res) => {
                if (res.Status == 1) {
                    dispatch(wishlistRemoveItemSuccess(product));
                    resolve();
                }
            });
        });
}

export function syncWishlist(products) {
    return {
        type: SYNC_WISHLIST,
        products,
    };
}
