import { getHeader, paramBuilder } from "../services/utils";
import { getMemberCode } from "./auth";
import store from "../store";
import { updateUser } from "../store/user";
const axios = require("axios");

export async function getUserDetail() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/customer_detail/" + getMemberCode();
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                store.dispatch(updateUser(data));
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function updateUserDetail(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/update_customer/" + getMemberCode(), data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function updateAvatar(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/upload_customer_picture/" + getMemberCode(), data, {
                headers: { ...getHeader(), "Content-Type": "multipart/form-data" },
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function changePassword(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/update_customer_password/" + getMemberCode(), data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                if (data.Status == 1) {
                    resolve(data);
                } else {
                    throw data;
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getAddressList(params) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/address_listing/" + getMemberCode() + "?";

    if (params?.limit) url += "limit=" + params.limit;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getAddress(addressID) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/address_detail/" + getMemberCode() + `/${addressID}`;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getDefaultAddress() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/address_detail_default/" + getMemberCode();

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function addAddress(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/add_address/" + getMemberCode(), data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                if (data.Status == 1) {
                    resolve(data);
                } else {
                    throw data;
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function updateAddress(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/update_address/" + getMemberCode(), data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                if (data.Status == 1) {
                    resolve(data);
                } else {
                    throw data;
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function deleteAddress(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/delete_address/" + getMemberCode(), data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getNotifications(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/notification_listing" + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
