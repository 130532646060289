// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import Rating from "../shared/Rating";
import { Cross12Svg } from "../../svg";
import { decodeChineseText, getNoImg, url } from "../../services/utils";
import { wishlistRemoveItem } from "../../store/wishlist";

// data stubs
import theme from "../../data/theme";
import BlockNoResult from "../blocks/BlockNoResult";
import { getFavoriteProducts } from "../../api/favorite";
import BlockLoader from "../blocks/BlockLoader";

function ShopPageWishlist(props) {
    const intl = useIntl();
    const { wishlist, wishlistRemoveItem } = props;
    const [loading, setLoading] = useState(true);

    let content;

    useEffect(() => {
        getFavoriteProducts().then((res) => {
            setLoading(false);
        });
    }, []);

    if (wishlist?.length > 0) {
        const itemsList = wishlist.map((item) => {
            let image;

            image = (
                <div className="product-image">
                    <Link to={url.product(item)} className="product-image__body">
                        <img
                            className="product-image__img"
                            src={item.product_picture ? item.product_picture : getNoImg()}
                            alt={item.product_code}
                            onError={(e) => (e.target.src = getNoImg())}
                        />
                    </Link>
                </div>
            );

            const renderAddToCarButton = ({ run, loading }) => {
                const classes = classNames("btn btn-primary btn-sm", {
                    "btn-loading": loading,
                });

                return (
                    <button type="button" onClick={run} className={classes}>
                        Add To Cart
                    </button>
                );
            };

            const renderRemoveButton = ({ run, loading }) => {
                const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                    "btn-loading": loading,
                });

                return (
                    <button type="button" onClick={run} className={classes} aria-label="Remove">
                        <Cross12Svg />
                    </button>
                );
            };

            return (
                <tr key={item.product_code} className="wishlist__row">
                    <td className="wishlist__column wishlist__column--image">{image}</td>
                    <td className="wishlist__column wishlist__column--product">
                        <Link to={url.product(item)} className="wishlist__product-name">
                            {decodeChineseText(item.product_name)}
                        </Link>
                        {/* <div className="wishlist__product-rating">
                            <Rating value={item.rating} />
                            <div className="wishlist__product-rating-legend">{`${item.reviews} Reviews`}</div>
                        </div> */}
                    </td>
                    {/* <td className="wishlist__column wishlist__column--stock">
                        <div className="badge badge-success">In Stock</div>
                    </td> */}
                    <td className="wishlist__column wishlist__column--price">
                        <Currency value={item.product_price} />
                    </td>
                    <td className="wishlist__column wishlist__column--remove">
                        <AsyncAction action={() => wishlistRemoveItem(item)} render={renderRemoveButton} />
                    </td>
                </tr>
            );
        });

        content = (
            <div className="block">
                <div className="container">
                    <table className="wishlist">
                        <thead className="wishlist__head">
                            <tr className="wishlist__row">
                                <th className="wishlist__column wishlist__column--image">
                                    <FormattedMessage id="image" defaultMessage="Image" />
                                </th>
                                <th className="wishlist__column wishlist__column--product">
                                    <FormattedMessage id="product" defaultMessage="Product" />
                                </th>
                                {/* <th className="wishlist__column wishlist__column--stock">Stock Status</th> */}
                                <th className="wishlist__column wishlist__column--price">
                                    <FormattedMessage id="price" defaultMessage="Price" />
                                </th>
                                {/* <th className="wishlist__column wishlist__column--tocart" aria-label="Add to cart" /> */}
                                <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="wishlist__body">{itemsList}</tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <BlockNoResult message={<FormattedMessage id="no_fav_msg" defaultMessage="Your favourites is empty" />} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${intl.formatMessage({ id: "my_fav", defaultMessage: "My Favourites" })} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header={<FormattedMessage id="my_fav" defaultMessage="My Favourites" />} breadcrumb={[]} />

            {loading ? <BlockLoader /> : content}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    wishlistRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageWishlist);
