// react
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// third-party
import { Helmet } from "react-helmet-async";
import { Link, Redirect, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import Currency from "../shared/Currency";
import theme from "../../data/theme";
import moment from "moment";

import { addOrder, calcDeliveryCharges, updateOrderStatusPaid } from "../../api/order";
import BlockLoader from "../blocks/BlockLoader";
import { useForm } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { addAddress, getAddress, getAddressList, getDefaultAddress } from "../../api/user";
import {
    billRoundOff,
    convertAddressObjectToString,
    convertToPrice,
    decodeChineseText,
    getFinalPrice,
    getNoImg,
    showGeneralError,
} from "../../services/utils";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { DELIVERY_METHOD, PICKUP_METHOD, serviceMethod } from "../../data/generalData";
import AddressForm from "../shared/AddressForm";
import { getMemberVoucher, validateProductVoucher } from "../../api/voucher";
import Voucher from "../shared/Voucher";
import { removeCart } from "../../api/cart";
import { openDeliveryModal, setUserAddress } from "../../store/user";
import PageHeader from "../shared/PageHeader";
import BlockNoResult from "../blocks/BlockNoResult";

let orderParams = {};

function ShopPageCheckout(props) {
    const history = useHistory();
    const intl = useIntl();
    const { location } = props;
    const cartItems = location.state && location.state.cartItems ? location.state.cartItems : null;
    const voucherApplied = location.state && location.state.voucherApplied ? location.state.voucherApplied : null;
    const cart = useSelector((state) => state.cart);
    let settings = useSelector((state) => state?.generalInfo.info);
    let deliveryMethod = useSelector((state) => state?.user?.deliveryMethod);
    let selectedAddress = deliveryMethod.address;

    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        formState: { errors },
        setError,
        clearErrors,
        unregister,
    } = useForm();
    const [loading, setLoading] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [productSubtotal, setProductSubtotal] = useState(0);
    const [selectedServiceMethod, setSelectedServiceMethod] = useState(deliveryMethod?.type);

    const [addressList, setAddressList] = useState([]);
    const [addressModalVisible, setAddressModalVisible] = useState(0); //0 = off, 1=bill, 2=ship
    const [userShipAddress, setUserShipAddress] = useState(null);
    const [userBillAddress, setUserBillAddress] = useState(null);
    const [shippingFee, setShippingFee] = useState(0);
    const [addressModalMode, setAddressModalMode] = useState("edit");
    const [voucherModalVisible, setVoucherModalVisible] = useState(false);
    const [vouchers, setVouchers] = useState([]);
    const [selectedVoucher, setSelectedVoucher] = useState(null);
    const [discount, setDiscount] = useState(0);

    useEffect(() => {
        if (deliveryMethod?.type == PICKUP_METHOD) {
            //the delivery method date time is expired or not
            if (moment().isAfter(deliveryMethod.date + " " + deliveryMethod.time)) {
                toast.error(
                    <FormattedMessage
                        id="pickup_datetime_expired"
                        defaultMessage="The delivery method date time is expired, please select a new one"
                    />
                );
                dispatch(openDeliveryModal(true));
                history.push("/cart");
            }
        } else {
            if(deliveryMethod?.address?.add_id){
                getAddress(deliveryMethod?.address?.add_id).then((res) => {
                    if(res.add_state)
                        dispatch(setUserAddress(res));
                });
            }
        }

        let productArr = [],
            productSubtotal = 0;
        for (let i = 0; i < cartItems?.length; i++) {
            let price = getFinalPrice(cartItems[i])
            let amount = parseInt(cartItems[i].product_quantity) * parseFloat(price)
            productSubtotal += amount;
            productArr.push({
                product_code: cartItems[i].product_code,
                product_quantity: parseInt(cartItems[i].product_quantity),
                product_price: parseFloat(price).toFixed(2),
                product_amount: parseFloat(amount).toFixed(2),
            });
        }
        orderParams.product = productArr;
        setProductSubtotal(parseFloat(productSubtotal).toFixed(2));
        setUserBillAddress(selectedAddress);
        setUserShipAddress(selectedAddress);
        if (deliveryMethod?.type == DELIVERY_METHOD) {
            calculateShippingFee(selectedAddress);
        } else setLoading(false);

        // getDefaultAddress().then((res) => {
        //     if (res.Error) {
        //         toast.info(
        //             <FormattedMessage
        //                 id="default_address_error_toast"
        //                 defaultMessage="You don't have default address yet, please create a new one to proceed."
        //             />
        //         );
        //         history.push("/account/addresses/add");
        //     } else {
        //         setUserBillAddress(res);
        //         setUserShipAddress(res);
        //         if (deliveryMethod?.type == DELIVERY_METHOD) {
        //             calculateShippingFee(res);
        //         }
        //         setLoading(false);
        //     }
        // });
    }, []);

    useEffect(() => {
        getMemberVoucher().then((res) => {
            setVouchers(res);
        });
    }, []);

    useEffect(() => {
        if (addressModalVisible == 0) {
            setTimeout(() => {
                setAddressModalMode("edit");
            }, 500);
        }
    }, [addressModalVisible]);

    useEffect(() => {
        let disc = 0;
        if (selectedVoucher) {
            validateProductVoucher({ product: orderParams.product }, selectedVoucher.vc_code).then((res) => {
                if (res.Status == 1) {
                    disc = checkVoucherDiscount();
                    handlePriceChange(disc);
                } else {
                    toast.error(res.Error);
                    setSelectedVoucher(null);
                }
            });
        } else {
            handlePriceChange(0);
        }
    }, [productSubtotal, selectedVoucher, shippingFee]);

    const handlePriceChange = (disc) => {
        let tt = parseFloat(parseFloat(productSubtotal) + parseFloat(shippingFee) - disc);
        if (tt < 0) tt = 0;
        setTotalAmount(tt);
        setDiscount(disc);
    };

    const calculateShippingFee = (address) => {
        let params = { product: orderParams.product };
        params.state = address?.add_state;
        params.distance_km = deliveryMethod?.store?.distance_km;

        calcDeliveryCharges(params).then((res) => {
            setShippingFee(res.delivery_charges);
            setLoading(false);
        });
    };

    const renderCardTitle = (name) => {
        return <h5 className="card-title d-flex">{name}</h5>;
    };

    const onError = (errors, e) => {
        if (process.env.NODE_ENV !== "production") console.log(errors, e);
    };

    function redirectToOrderDetail(orderRefNo) {
        history.push("/account/orders/" + orderRefNo, {
            status: "all",
            page: 1,
        });
    }

    const onSubmit = (data) => {
        setApiLoading(true);
        let amountAfterRoundOff = billRoundOff(totalAmount);
        let orderInfo = {
            product: orderParams.product,
            order_date: moment(new Date()).format("YYYY-MM-DD"),
            order_del_type: selectedServiceMethod,
            order_amount: amountAfterRoundOff,
            order_bill_country: userBillAddress?.add_country,
            action: "purchase",
            order_bill_person: userBillAddress?.add_person,
            order_bill_phone: userBillAddress?.add_phone,
            order_bill_address1: userBillAddress?.add_address1,
            order_bill_address2: userBillAddress?.add_address2,
            order_bill_postcode: userBillAddress?.add_postcode,
            order_bill_city: userBillAddress?.add_city,
            order_bill_state: userBillAddress?.add_state,
            order_bill_country: userBillAddress?.add_country,
            bill_platform: "website",
        };
        if (amountAfterRoundOff != totalAmount) {
            orderInfo.order_rounding = parseFloat(parseFloat(amountAfterRoundOff) - totalAmount).toFixed(2);
        } else orderInfo.order_rounding = "0.00";
        if (data.order_remark) orderInfo.order_remark = data.order_remark;
        orderInfo.order_del_pickup_store_code = deliveryMethod?.store?.store_code;

        orderInfo.distance_km = deliveryMethod?.store?.distance_km;

        if (selectedServiceMethod == PICKUP_METHOD) {
            orderInfo.order_del_pickup_date = deliveryMethod?.date;
            orderInfo.order_del_pickup_time = deliveryMethod?.time;
            orderInfo.order_del_person = userBillAddress?.add_person;
            orderInfo.order_del_phone = userBillAddress?.add_phone;
        } else {
            orderInfo.order_delivery_charges = shippingFee;
            orderInfo.order_del_person = userShipAddress?.add_person;
            orderInfo.order_del_phone = userShipAddress?.add_phone;
            orderInfo.order_del_address1 = userShipAddress?.add_address1;
            orderInfo.order_del_address2 = userShipAddress?.add_address2;
            orderInfo.order_del_postcode = userShipAddress?.add_postcode;
            orderInfo.order_del_city = userShipAddress?.add_city;
            orderInfo.order_del_state = userShipAddress?.add_state;
            orderInfo.order_del_country = userShipAddress?.add_country;
        }
        if (selectedVoucher) {
            orderInfo.order_voucher_code = selectedVoucher?.vc_code;
            orderInfo.order_voucher_amount = discount;
            orderInfo.order_voucher_no = selectedVoucher?.vm_voucher_no;
            orderInfo.order_voucher_name = selectedVoucher?.vc_name;
        }
        addOrder(orderInfo)
            .then((res) => {
                if (res.Status == 1) {
                    removeCart(null);
                    if (amountAfterRoundOff <= 0) {
                        updateOrderStatusPaid({
                            order_refno: res?.order_refno,
                        }).then((response) => {
                            redirectToOrderDetail(res?.order_refno);
                        });
                    } else {
                        redirectToOrderDetail(res?.order_refno);
                    }
                } else {
                    showGeneralError(res);
                    setApiLoading(false);
                }
            })
            .catch((err) => {
                showGeneralError(err);
                setApiLoading(false);
            });
    };

    if (cartItems?.length < 1) {
        return <Redirect to="cart" />;
    }

    const renderTotals = () => {
        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th colSpan={2}>
                            <FormattedMessage id="subtotal" defaultMessage="Subtotal" />
                        </th>
                        <td>
                            <Currency value={productSubtotal} />
                        </td>
                    </tr>

                    {shippingFee && selectedServiceMethod == DELIVERY_METHOD ? (
                        <tr>
                            <th colSpan={2}>
                                <FormattedMessage id="shipping_fee" defaultMessage="Shipping Fee" /> ({deliveryMethod?.store.distance_km}{" "}
                                km)
                            </th>
                            <td>
                                <Currency value={shippingFee} />
                            </td>
                        </tr>
                    ) : null}

                    {/* {vouchers && vouchers.length > 0 ? ( */}
                    <tr>
                        {selectedVoucher ? (
                            <th colSpan={2} className="link-button" onClick={() => setVoucherModalVisible(true)}>
                                {selectedVoucher ? <FormattedMessage id="discount_voucher" defaultMessage="Discount Voucher" /> : null}
                            </th>
                        ) : (
                            <th colSpan={2} className="link-button" onClick={() => setVoucherModalVisible(true)}>
                                <FormattedMessage id="use_voucher" defaultMessage="Use Voucher" />
                            </th>
                        )}

                        <td>
                            {selectedVoucher ? "-" : null}
                            {selectedVoucher ? <Currency value={discount} /> : null}
                        </td>
                    </tr>
                    {/* ) : null} */}
                </tbody>
            </React.Fragment>
        );
    };

    const storeAddress = (data) => {
        addAddress([data])
            .then((res) => {
                toast.success("Address has been added", { autoClose: 3000 });
                handleChangeAddress(data);
                setAddressList([...addressList, data]);
            })
            .catch((err) => {
                showGeneralError(err);
            });
    };

    function handleChangeAddress(data) {
        if (addressModalVisible == 1) setUserBillAddress(data);
        else {
            setUserShipAddress(data);
            calculateShippingFee(data);
        }
        setAddressModalVisible(0);
    }

    function checkVoucherDiscount() {
        let discount = 0;
        if (selectedVoucher) {
            if (selectedVoucher && selectedVoucher.vc_amount.includes("%")) {
                let percent = convertToPrice(selectedVoucher.vc_amount);
                let total = 0;
                total = productSubtotal + (shippingFee ? convertToPrice(shippingFee) : 0);
                discount = parseFloat(total) * (percent / 100);
            } else discount = convertToPrice(selectedVoucher?.vc_amount);
        }

        return discount;
    }

    const renderCart = () => {
        const items = cartItems.map((item, index) => {
            return (
                <tr key={index}>
                    <td style={{ width: 50 }}>
                        <img
                            style={{ width: "100%" }}
                            src={item.product_picture ? item.product_picture : getNoImg()}
                            onError={(e) => (e.target.src = getNoImg())}
                            alt=""
                        />
                    </td>
                    <td>{`${decodeChineseText(item.product_name)} × ${parseInt(item.product_quantity)}`}</td>
                    <td>
                        <Currency value={parseFloat(getFinalPrice(item)) * parseInt(item.product_quantity)} />
                    </td>
                </tr>
            );
        });

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>
                            <FormattedMessage id="product" defaultMessage="Product" />
                        </th>
                        <th></th>
                        <th>
                            <FormattedMessage id="total" defaultMessage="Total" />
                        </th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">{items}</tbody>
                {renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th colSpan={2}>
                            <FormattedMessage id="total" defaultMessage="Total" />
                        </th>
                        <td>
                            <Currency value={totalAmount} />
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    };

    const renderAddressModal = () => {
        return (
            <Modal isOpen={addressModalVisible == 0 ? false : true} centered size="lg">
                <ModalHeader toggle={() => setAddressModalVisible(0)}>
                    {(addressModalMode == "add"
                        ? intl.formatMessage({ id: "add", defaultMessage: "Add" })
                        : intl.formatMessage({ id: "change", defaultMessage: "Change" })) +
                        " " +
                        intl.formatMessage({ id: "footer.address", defaultMessage: " Address" })}
                </ModalHeader>
                <ModalBody className="container col-12 col-lg-11">
                    {addressModalMode == "add" ? (
                        <AddressForm submitAddress={(data) => storeAddress(data)} />
                    ) : (
                        <>
                            <button className="btn btn-primary" onClick={() => setAddressModalMode("add")}>
                                <FormattedMessage id="add_address" defaultMessage="Add New Address" />
                            </button>
                            <div className="scroll-list-container px-0">
                                {addressList?.map((item) => {
                                    return (
                                        <div
                                            key={item.add_id}
                                            className="mb-3 c-pointer"
                                            onClick={() => {
                                                handleChangeAddress(item);
                                            }}
                                        >
                                            {renderAddressBox(item)}
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </ModalBody>
            </Modal>
        );
    };

    const renderVoucherModal = () => {
        return (
            <Modal isOpen={voucherModalVisible} centered style={{ maxWidth: 600 }}>
                <ModalHeader toggle={() => setVoucherModalVisible(false)}>
                    <FormattedMessage id="select_voucher" defaultMessage="Select Voucher" />
                </ModalHeader>
                <ModalBody className="scroll-list-container col-12">
                    {vouchers.length == 0 ? (
                        <div>
                            <BlockNoResult
                                message={
                                    <FormattedMessage
                                        id="no_available_voucher"
                                        defaultMessage="No active vouchers available, please contact our staff for more information"
                                    />
                                }
                                textSize="small"
                            />
                        </div>
                    ) : (
                        vouchers.map((item, index) => {
                            return (
                                <Voucher
                                    key={item.vm_voucher_no + index}
                                    voucher={item}
                                    onClick={(item) => {
                                        setSelectedVoucher(item);
                                        setVoucherModalVisible(false);
                                    }}
                                    layout="full-width"
                                />
                            );
                        })
                    )}
                </ModalBody>
            </Modal>
        );
    };

    const renderAddressBox = (address, type, editable) => {
        return (
            <div className="card px-3 py-3 mt-2">
                {/* {editable ? (
                    <div
                        className="c-pointer"
                        style={{ position: "absolute", right: 10, top: 5 }}
                        onClick={() => {
                            handleOpenAddressModal(type);
                        }}
                    >
                        <i className="fas fa-pencil-alt text-color-theme" aria-hidden="true" />
                    </div>
                ) : null} */}

                <div style={{ fontSize: 15 }}>
                    <div>
                        <i className="fa fa-user text-color-theme mr-2 pr-1" style={{ paddingLeft: 2 }} />
                        {address?.add_person}
                    </div>
                    <div>
                        <i className="fa fa-phone text-color-theme mr-2 pr-1" />
                        {address?.add_phone}
                    </div>
                    <div>
                        <i className="fas fa-map-marker-alt text-color-theme mr-3" />
                        {convertAddressObjectToString(address)}
                    </div>
                </div>
            </div>
        );
    };

    //show different info depends the shipping method user select at first
    const renderServiceMethodCard = () => {
        return (
            <div className=" mb-4">
                <div className="mb-2">
                    <b>
                        <FormattedMessage id="service_mtd" defaultMessage="Service Method" /> :{" "}
                    </b>
                    {serviceMethod.find((item) => item.code == selectedServiceMethod).title}
                </div>
                {selectedServiceMethod == PICKUP_METHOD ? (
                    <div>
                        <div className="align-items-center flex-wrap mb-3">
                            {renderCardTitle(<FormattedMessage id="pickup_store_address" defaultMessage="Pick-Up Store Address" />)}
                        </div>
                        <div className="card px-3 py-2 mt-2">
                            <div>
                                {deliveryMethod?.store?.store_name}
                                <br />
                                <div style={{ paddingTop: "3px" }}>
                                    <small>
                                        <i className="fas fa-map-marker-alt text-color-theme"></i>&nbsp;
                                        {deliveryMethod?.store?.store_address}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="pt-1">
                            {serviceMethod.find((item) => item.code == selectedServiceMethod).title}&nbsp;
                            {selectedServiceMethod == PICKUP_METHOD ? (
                                intl.formatMessage({ id: "on", defaultMessage: "on" }) +
                                " " +
                                moment(deliveryMethod.date).format("DD/MM/YYYY") +
                                " " +
                                moment(deliveryMethod.time, "HH:mm").format("hh:mm a")
                            ) : (
                                <FormattedMessage id="to" defaultMessage="to" />
                            )}
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="align-items-center flex-wrap mb-3">
                            {renderCardTitle(<FormattedMessage id="delivery_address" defaultMessage="Delivery Address" />)}
                        </div>
                        {renderAddressBox(userShipAddress, 2, false)}
                    </>
                )}
            </div>
        );
    };

    const renderDeliveryDetailsCard = () => {
        return (
            <div className="mb-4">
                {/* <div className="card-title align-items-center flex-wrap">
                    {renderCardTitle(<FormattedMessage id="billing_detail" defaultMessage="Billing Details" />)}
                </div>
                {selectedServiceMethod == PICKUP_METHOD ? renderAddressBox(userBillAddress, 1, true) : null} */}
                <div className="form-group mt-4">
                    <label htmlFor="checkout-comment">
                        <FormattedMessage id="order_remark" defaultMessage="Order Remark" />{" "}
                        <span className="text-muted">
                            (<FormattedMessage id="optional" defaultMessage="Optional" />)
                        </span>
                    </label>
                    <textarea
                        id="order_remark"
                        name="order_remark"
                        placeholder={intl.formatMessage({
                            id: "order_remark_msg",
                            defaultMessage: "Notes about your order, e.g. special notes for delivery.",
                        })}
                        {...register("order_remark", {
                            maxLength: {
                                value: 180,
                                message: <FormattedMessage id="value_max_180" defaultMessage="remark should not exceed 180 characters" />,
                            },
                        })}
                        className="form-control"
                        rows="3"
                    />
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${intl.formatMessage({ id: "checkout", defaultMessage: "Checkout" })} — ${theme.name}`}</title>
            </Helmet>
            <PageHeader />
            {loading ? (
                <div className="block-empty-page">
                    <BlockLoader />
                </div>
            ) : (
                <div className="checkout block">
                    <div className="container">
                        {renderAddressModal()}
                        {renderVoucherModal()}
                        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="col-12 col-lg-6 col-xl-7">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        {renderServiceMethodCard()}
                                        {renderDeliveryDetailsCard()}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 col-xl-5 mt-lg-0">
                                <div className={cartItems.length > 20 ? "card mb-4" : "checkout-summary-card card mb-4"}>
                                    <div className="card-body">
                                        {/* <h3 className="card-title">Your Order</h3> */}
                                        {renderCart()}

                                        <button
                                            type="submit"
                                            disabled={apiLoading}
                                            className={"btn btn-lg btn-block" + (apiLoading ? " btn-loading" : " btn-primary ")}
                                        >
                                            <FormattedMessage id="submit_order" defaultMessage="Submit Order" />
                                        </button>
                                        {errors["shipping_weight"] !== null ? (
                                            <div className="mb-2">
                                                <small className="text-danger">{errors["shipping_weight"]?.message}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default ShopPageCheckout;
