import { getHeader } from "../services/utils";
import store from "../store";
import { storeGeneralInfo } from "../store/generalInfo";
import { getMemberCode } from "./auth";
const axios = require("axios");

export async function getCompanyContact() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/company_contact";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
                store.dispatch(storeGeneralInfo(data));
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function sendPhoneVerification(phoneNumber) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/phone_verification/" + phoneNumber;
    return new Promise((resolve, reject) => {
        axios
            .post(url, null, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getHomeBanner() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/mobile_ecommerce_header_banner";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getStates() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/state_listing";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getPromotionBanners() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/get_promotion_banner";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function sendSMS(data) {
    let url = "https://api.esms.com.my/sms/send";
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                Accept: "application/json",
                "Content-Type": "application/json",
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function verifyOTP(data) {
    let url = "https://api.esms.com.my/sms/otp/verify";
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                Accept: "application/json",
                "Content-Type": "application/json",
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function addFeedback(data) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/add_feedback/" + getMemberCode();
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getMyLanguage() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/customer_language/" + getMemberCode();
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function updateMyLanguage(data) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/update_language/" + getMemberCode();
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getPrivacyPolicy() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/customer_help_site";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function convertAddressToLatLong(address) {
    let url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=" + process.env.REACT_APP_GOOGLE_API_KEY;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: { Accept: "application/json", "Content-Type": "application/json" },
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function reachUsInfo() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/get_reach_us/BHU";
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}