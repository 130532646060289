// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

// application
import Currency from "../shared/Currency";
import { Check100Svg } from "../../svg";
import { serviceMethod, DELIVERY_METHOD } from "../../data/generalData";

// data stubs
import theme from "../../data/theme";
import { getNoImg, showGeneralError, url } from "../../services/utils";
import { getOrderPaymentUrl } from "../../api/order";

export default function ShopPageOrderSuccess(props) {
    const intl = useIntl();
    const { match, location } = props;
    const order = location.state && location.state.order ? location.state.order : null;
    const redirectionBtn = document.querySelector("#redirection-pay-btn");
    const returnProductSubtotal = order.product.reduce((total, item) => total + parseFloat(item.product_amount), 0);

    const items = order.product.map((item) => {
        return (
            <tr key={item.product_code}>
                <td className="order-list__column-image">
                    <div className="product-image">
                        <Link to={url.product(item)} className="product-image__body">
                            <img
                                className="product-image__img"
                                src={item.product_picture ? item.product_picture : getNoImg()}
                                alt={item.product_code}
                                onError={(e) => (e.target.src = getNoImg())}
                            />
                        </Link>
                    </div>
                </td>
                <td className="order-list__column-product">
                    <Link to={url.product(item)}>{item.product_name}</Link>
                </td>
                <td className="order-list__column-quantity" data-title="Qty:">
                    {item.product_quantity}
                </td>
                <td className="order-list__column-total">
                    <Currency value={item.product_amount} />
                </td>
            </tr>
        );
    });

    const handlePayment = () => {
        getOrderPaymentUrl(order.order_refno)
            .then((res) => {
                if (res.eGHL_url) {
                    redirectionBtn.addEventListener("click", () => {
                        window.open(res.eGHL_url, "", "width='80%,height=350");
                        redirectionBtn.setAttribute("disabled", true);
                    });
                    window.addEventListener("message", (e) => {
                        const { isPaymentCompleted } = e.data;
                        if (isPaymentCompleted) {
                            console.info("User has completed payment from the other tab!");
                        }
                    });
                } else {
                    showGeneralError(res);
                }
            })
            .catch((err) => {
                showGeneralError(err);
            });
    };

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`${intl.formatMessage({ id: "order_success", defaultMessage: "Order Success" })} — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        {/* <Check100Svg className="order-success__icon" />
                        <h1 className="order-success__title">Thank you</h1>
                        <div className="order-success__subtitle">Your order has been received</div> */}
                        <button id="redirection-pay-btn" className="btn btn-primary px-5" onClick={() => handlePayment()}>
                            <FormattedMessage id="pay_now" defaultMessage="Pay Now" />
                        </button>

                        {/* <div className="order-success__actions"></div> */}
                    </div>

                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">
                                    <FormattedMessage id="order_no" defaultMessage="Order No" />:
                                </span>
                                <span className="order-success__meta-value">{`#${order.order_refno}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">
                                    <FormattedMessage id="order_date" defaultMessage="Order Date" />:
                                </span>
                                <span className="order-success__meta-value">{order.order_date}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">
                                    <FormattedMessage id="total" defaultMessage="Total" />:
                                </span>
                                <span className="order-success__meta-value">
                                    <Currency value={order.order_amount} />
                                </span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">
                                    <FormattedMessage id="service_mtd" defaultMessage="Service Method" />:
                                </span>
                                <span className="order-success__meta-value">
                                    {serviceMethod.find((item) => item.code == order.order_del_type)?.title}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className="card">
                        <div className="order-list">
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">
                                            <FormattedMessage id="product" defaultMessage="Product" />
                                        </th>
                                        <th className="order-list__column-quantity">
                                            <FormattedMessage id="quantity" defaultMessage="Qty" />
                                        </th>
                                        <th className="order-list__column-total">
                                            <FormattedMessage id="total" defaultMessage="Total" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">{items}</tbody>
                                <tbody className="order-list__subtotals">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="3">
                                            <FormattedMessage id="subtotal" defaultMessage="Product Subtotal" />
                                        </th>
                                        <td className="order-list__column-total">
                                            <Currency value={returnProductSubtotal} />
                                        </td>
                                    </tr>
                                    {order.order_del_type == DELIVERY_METHOD ? (
                                        <tr>
                                            <th className="order-list__column-label" colSpan="3">
                                                <FormattedMessage id="shipping_fee" defaultMessage="Shipping Fee" />
                                            </th>
                                            <td className="order-list__column-total">
                                                <Currency value={order.order_delivery_charges} />
                                            </td>
                                        </tr>
                                    ) : null}
                                    {parseFloat(order.order_voucher_amount) > 0 ? (
                                        <tr>
                                            <th className="order-list__column-label" colSpan="3">
                                                <FormattedMessage id="discount_voucher" defaultMessage="Discount Voucher" />
                                            </th>
                                            <td className="order-list__column-total">
                                                - <Currency value={order.order_voucher_amount} />
                                            </td>
                                        </tr>
                                    ) : null}
                                </tbody>
                                <tfoot className="order-list__footer">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="3">
                                            <FormattedMessage id="total" defaultMessage="Total" />
                                        </th>
                                        <td className="order-list__column-total">
                                            <Currency value={order.order_amount} />
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    {order.order_remark ? (
                        <div className="card address-card mt-3">
                            <div className="address-card__body">
                                <div className="address-card__row">
                                    <b>
                                        <small>
                                            <FormattedMessage id="remark" defaultMessage="Remarks" />:
                                        </small>
                                    </b>
                                    <br />
                                    {order.order_remark}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div className="row mt-3 no-gutters mx-n2">
                        <div className={"col-12 px-2 mt-sm-0 mt-3 col-sm-6"}>
                            <div className="card address-card">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted">
                                        <FormattedMessage id="billing_address" defaultMessage="Billing Address" />
                                    </div>
                                    <div className="address-card__name">{`${order.order_bill_person}`}</div>
                                    <div className="address-card__row">
                                        {order.order_bill_address1}
                                        {order.order_bill_address2 ? (
                                            <>
                                                <br />
                                                {order.order_bill_address2}{" "}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        <br />
                                        {order.order_bill_postcode}
                                        {", " + order.order_bill_city}
                                        <br />
                                        {order.order_bill_state
                                            ? order.order_bill_state.charAt(0) + order.order_bill_state.toLowerCase().slice(1)
                                            : ""}
                                        {/* + ", " + (order.add_country ? order.add_country : "")} */}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">
                                            <FormattedMessage id="phone_no" defaultMessage="Phone Number" />
                                        </div>
                                        <div className="address-card__row-content">+{order.order_bill_phone}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {order.order_del_type == DELIVERY_METHOD ? (
                            <div className="col-sm-6 col-12 px-2">
                                <div className="card address-card">
                                    <div className="address-card__body">
                                        <div className="address-card__badge address-card__badge--muted">
                                            <FormattedMessage id="shipping_address" defaultMessage="Shipping Address" />
                                        </div>
                                        <div className="address-card__name">{`${order.order_del_person}`}</div>
                                        <div className="address-card__row">
                                            {order.order_del_address1}
                                            {order.order_del_address2 ? (
                                                <>
                                                    <br />
                                                    {order.order_del_address2}{" "}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            <br />
                                            {order.order_del_postcode}
                                            {", " + order.order_del_city}
                                            <br />
                                            {order.order_del_state
                                                ? order.order_del_state.charAt(0) + order.order_del_state.toLowerCase().slice(1)
                                                : ""}
                                            {/* + ", " + (order.add_country ? order.add_country : "")} */}
                                        </div>
                                        <div className="address-card__row">
                                            <div className="address-card__row-title">
                                                <FormattedMessage id="phone_no" defaultMessage="Phone Number" />
                                            </div>
                                            <div className="address-card__row-content">{order.order_del_phone}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-sm-6 col-12 px-2">
                                <div className="card address-card">
                                    <div className="address-card__body">
                                        <div className="address-card__badge address-card__badge--muted">
                                            <FormattedMessage id="pickup_info" defaultMessage="Pick Up Info" />
                                        </div>
                                        <div className="address-card__name">{`${order.order_del_pickup_store_name}`}</div>
                                        <div className="address-card__row">
                                            <div className="address-card__row-title">
                                                <FormattedMessage id="pickup_date" defaultMessage="Pick Up Date" />
                                            </div>
                                            <div className="address-card__row-content">{order.order_del_pickup_date}</div>
                                        </div>
                                        <div className="address-card__row">
                                            <div className="address-card__row-title">
                                                <FormattedMessage id="pickup_time" defaultMessage="Pick Up Time" />
                                            </div>
                                            <div className="address-card__row-content">{order.order_del_pickup_time}</div>
                                        </div>
                                        <div className="address-card__row">
                                            <div className="address-card__row-title">
                                                <FormattedMessage id="phone_no" defaultMessage="Contact Number" />
                                            </div>
                                            <div className="address-card__row-content">{order.order_del_pickup_store_phone}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
