import { LOCALE_CHANGE } from "./localeActionTypes";

const initialState = localStorage.getItem("LG") && localStorage.getItem("LG") != "undefined" ? localStorage.getItem("LG") : "EN";

export default function localeReducer(state = initialState, action) {
    if (action.type === LOCALE_CHANGE) {
        localStorage.setItem("LG", action.locale);
        return action.locale;
    }

    return state;
}
