// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// application
import NavPanel from "./NavPanel";
import Search from "./Search";
import Topbar from "./Topbar";
import { Heart20Svg, LogoSvg } from "../../svg";
import CartIndicator from "./IndicatorCart";
import { connect } from "react-redux";
import IndicatorAccount from "./IndicatorAccount";
import { mobileMenuOpen } from "../../store/mobile-menu";
import { getMemberCode } from "../../api/auth";
import IndicatorNotifcation from "./IndicatorNotifcation";
import Indicator from "./Indicator";
import AppLink from "../shared/AppLink";
import Menu from "./Menu";
import { url } from "../../services/utils";
import cloneDeep from "lodash/cloneDeep";
import { ArrowRoundedDown9x6Svg } from "../../svg";

const headerMenuList = [
    { id: 1, name: <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us"/>, url: "/about-us" },
    { id: 2, name: <FormattedMessage id="shop" defaultMessage="Products"/>, url: "/category" },
    // { id: 3, name: <FormattedMessage id="membership" defaultMessage="Membership"/>, url: "/" },
    { id: 4, name: <FormattedMessage id="footer.contactUs" defaultMessage="Contact Us"/>, url: "/outlets" },
];
function Header(props) {
    const { layout, openMobileMenu, wishlist, categories } = props;
    const code = getMemberCode();
    const handleMouseEnter = (event) => {
        const item = event.currentTarget;
    };
    let customCategories;
    if (categories) {
        customCategories = cloneDeep(categories);
    }

    let bannerSection;
    let arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;

    if (layout === "default") {
        bannerSection = (
            <div className="site-header__middle">
                {/* <button type="button" className="mobile-header__menu-button desktop" onClick={openMobileMenu}>
                    <div className="bar-style">
                        <i className="fa fa-bars sidebar-bar" aria-hidden="true"></i>
                    </div>
                </button> */}
                <div className="site-header__logo">
                    <Link to="/">
                        <img src={"images/logos/logo.png?v=" + new Date()} alt="logo" className="header-logo" />
                    </Link>
                </div>
                <div className="header-menu nav-panel__nav-links nav-links">
                    <ul className="nav-links__list">
                        {headerMenuList.map((item) => {
                            return item.menu ? (
                                <li
                                    key={item.id}
                                    className={"nav-links__item" + (item.menu ? " nav-links__item--with-submenu" : "")}
                                    onMouseEnter={handleMouseEnter}
                                >
                                    <AppLink to={item.url} {...item.props}>
                                        <span>
                                            {item.name}
                                            {customCategories && customCategories.length > 0 ? arrow : null}
                                        </span>
                                    </AppLink>
                                    {customCategories && customCategories.length > 0 ? (
                                        <div className="nav-links__menu">
                                            <Menu items={customCategories} type={item.name} />
                                        </div>
                                    ) : null}
                                </li>
                            ) : (
                                <li key={item.id}>
                                    <Link to={item.url}>{item.name}</Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__user">
                    {code ? (
                        <>
                            {/* <IndicatorNotifcation from="header" /> */}
                            <Indicator url="/favourites" value={wishlist.length} icon={<Heart20Svg />} />
                        </>
                    ) : null}

                    <CartIndicator from="header" />

                    <IndicatorAccount from="header" />
                </div>

                {/* <div className="menu-right-top">
                    {token ? null : <Link to="/login">Login / Resgiter</Link>}
                </div> */}
            </div>
        );
    }

    return (
        <div className="site-header">
            {window.location.pathname !== "/checkout" && window.location.pathname !== "/cart" ? <Topbar /> : null}
            <div className="container">{bannerSection}</div>
            {/* <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div> */}
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
    layout: "default",
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
