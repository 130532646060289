import { getMemberCode } from "../../api/auth";
import { updateMyLanguage } from "../../api/general";
import { LOCALE_CHANGE } from "./localeActionTypes";

// eslint-disable-next-line import/prefer-default-export
export const localeChange = (locale) => ({
    type: LOCALE_CHANGE,
    locale,
});

export function changeUserLanguage(code) {
    const token = getMemberCode();

    return (dispatch) =>
        new Promise((resolve) => {
            if (token) {
                updateMyLanguage({ language: code }).then((res) => {
                    if (res.Status == 1) {
                        dispatch(localeChange(code));
                        resolve();
                    }
                });
            } else {
                dispatch(localeChange(code));
                resolve();
            }
        });
}
