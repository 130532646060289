// react
import React, { useEffect, useState } from "react";

// application
import Pagination from "../shared/Pagination";
import Rating from "react-rating";

// data stubs
import { decodeChineseText, getDefaultAvatar, showGeneralError } from "../../services/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import BlockLoader from "../blocks/BlockLoader";
import BlockNoResult from "../blocks/BlockNoResult";
import { FormattedMessage } from "react-intl";

const perPage = 10;

function ProductTabReviews(props) {
    const { reviews } = props;
    const [limitPageRange, setLimitPageRange] = useState("0,8");
    const [loading, setLoading] = useState(true);
    const [reviewList, setReviewList] = useState([]);
    const [allReviewList, setAllReviewList] = useState([]);
    const [rateOverview, setRateOverview] = useState(0);

    useEffect(() => {
        if (reviews) {
            let list = reviews.list;
            setAllReviewList(list);
            setReviewList(list);
            setRateOverview(reviews.rating);
            setLoading(false);
        }
    }, [reviews]);

    const reviewsList = reviewList.map((review, index) => (
        <li key={index} className="reviews-list__item">
            <div className="review">
                <div className="review__avatar">
                    <img
                        src={review.avatar ? review.avatar : getDefaultAvatar()}
                        alt={"user-review-avatar-" + index}
                        onError={(e) => (e.target.src = getDefaultAvatar())}
                    />
                </div>
                <div className="review__content">
                    <div className="review__author">{review.member}</div>
                    <div className="review__rating">
                        <Rating
                            readonly
                            initialRating={parseInt(review.review_rate)}
                            className="rating-star"
                            emptySymbol={"far fa-star"}
                            fullSymbol="fas fa-star"
                        />
                        {/* <MyRating value={parseInt(review.review_rate)} /> */}
                    </div>
                    <div className="review__text">{decodeChineseText(review.review_comment)}</div>
                    <div className="review__date">{review.review_datetime}</div>
                </div>
            </div>
        </li>
    ));

    const handleLoadMore = () => {
        let temp = limitPageRange.split(",");
        let startIndex = parseInt(temp[0]) + perPage;
        let endIndex = parseInt(temp[1]) + perPage;
        setLimitPageRange(startIndex + "," + endIndex);
    };

    const handleFilterChange = (val) => {
        let temp = allReviewList;
        if (val == "all") setReviewList(temp);
        else setReviewList(temp.filter((item) => item.review_rate == val));
    };

    return (
        <div className="reviews-view">
            <div className="reviews-view__list">
                {/* <h3 className="reviews-view__header">Customer Reviews</h3> */}

                {loading ? (
                    <BlockLoader />
                ) : (
                    <div className="reviews-list">
                        {reviews.list?.length > 0 ? (
                            <>
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div className="d-flex mb-3">
                                        <Rating
                                            readonly
                                            initialRating={rateOverview}
                                            className="rating-star mr-3"
                                            emptySymbol={"far fa-star"}
                                            fullSymbol="fas fa-star"
                                        />
                                        <div className="rating-overview">
                                            <span>{rateOverview}/5.0</span> ({reviewList.length}{" "}
                                            <FormattedMessage id="reviews" defaultMessage="Reviews" />)
                                        </div>
                                    </div>
                                    <div>
                                        <select defaultValue={"all"} onChange={(e) => handleFilterChange(e.target.value)}>
                                            <option value="all">All</option>
                                            <option value="5">5 stars</option>
                                            <option value="4">4 stars</option>
                                            <option value="3">3 stars</option>
                                            <option value="2">2 stars</option>
                                            <option value="1">1 stars</option>
                                            <option value="0">0 stars</option>
                                        </select>
                                    </div>
                                </div>
                                <hr />
                            </>
                        ) : null}

                        <ol className="reviews-list__content pt-3">
                            {reviewList?.length > 0 ? (
                                reviewsList
                            ) : (
                                <div className="container">
                                    <BlockNoResult
                                        message={<FormattedMessage id="no_review_msg" defaultMessage="No review yet" />}
                                        textSize="small"
                                    />
                                </div>
                            )}
                        </ol>
                        {/* <div className="reviews-list__pagination">
                        <Pagination current={1} siblings={2} total={10} />
                    </div> */}
                    </div>
                )}
            </div>

            {/* <form className="reviews-view__form">
                <h3 className="reviews-view__header">Write A Review</h3>
                <div className="row">
                    <div className="col-12 col-lg-9 col-xl-8">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="review-stars">Review Stars</label>
                                <select id="review-stars" className="form-control">
                                    <option>5 Stars Rating</option>
                                    <option>4 Stars Rating</option>
                                    <option>3 Stars Rating</option>
                                    <option>2 Stars Rating</option>
                                    <option>1 Stars Rating</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="review-author">Your Name</label>
                                <input type="text" className="form-control" id="review-author" placeholder="Your Name" />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="review-email">Email Address</label>
                                <input type="text" className="form-control" id="review-email" placeholder="Email Address" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="review-text">Your Review</label>
                            <textarea className="form-control" id="review-text" rows="6" />
                        </div>
                        <div className="form-group mb-0">
                            <button type="submit" className="btn btn-primary btn-lg">
                                Post Your Review
                            </button>
                        </div>
                    </div>
                </div>
            </form> */}
        </div>
    );
}

export default ProductTabReviews;
