import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES, SYNC_MYCART } from "./cartActionTypes";
import { toast } from "react-toastify";

export function syncMyCart(products) {
    return {
        type: SYNC_MYCART,
        products,
    };
}

export function cartAddItemSuccess(product, quantity = 1, history) {
    if (quantity > 0 && window.location.pathname !== "/cart") {
        let name = product.product_name ? product.product_name : product.product_description;
        toast.success(`"${name}" added to cart`, {
            autoClose: 2000,
            onClick: () => {
                if (history) history.push("/cart");
            },
        });
    }

    return {
        type: CART_ADD_ITEM,
        product,
        quantity,
    };
}

export function cartRemoveItemSuccess(itemCode) {
    return {
        type: CART_REMOVE_ITEM,
        itemCode,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartAddItem(product, options = [], quantity = 1, history) {
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, options, quantity, history));
                resolve();
            }, 500);
        });
}

export function cartRemoveItem(itemCode) {
    return (dispatch) =>
        new Promise((resolve) => {
            // removeCart([{ product_code: itemCode }]).then((res) => {
            //     if (res.Status == 1) {
            //         dispatch(cartRemoveItemSuccess(itemCode));
            //         resolve();
            //     }
            // });
        });
}
