// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// application
import PageHeader from "../shared/PageHeader";
import Product from "../shared/Product";
import ProductTabs from "./ProductTabs";
import shopApi from "../../api/shop";
import { showGeneralError, url } from "../../services/utils";

// blocks
import BlockLoader from "../blocks/BlockLoader";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";

// widgets
import WidgetCategories from "../widgets/WidgetCategories";
import WidgetProducts from "../widgets/WidgetProducts";

// data stubs
import categories from "../../data/shopWidgetCategories";
import theme from "../../data/theme";
import { getProductDetails } from "../../api/product";
import { getProductReview } from "../../api/product";
import { useSelector } from "react-redux";

function ShopPageProduct(props) {
    const { productNo, layout, sidebarPosition } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [latestProducts, setLatestProducts] = useState([]);
    const [reviewOverview, setReviewOverview] = useState(null);
    let deliveryMethod = useSelector((state) => state?.user?.deliveryMethod);

    // Load product.
    useEffect(() => {
        let canceled = false;
        setIsLoading(true);
        getProductDetails(productNo).then((product) => {
            if (canceled) {
                return;
            }

            setProduct(product);
            setIsLoading(false);
        });

        return () => {
            canceled = true;
        };
    }, [productNo, deliveryMethod?.store?.store_code]);

    useEffect(() => {
        if(productNo){
            getProductReview(productNo)
            .then((res) => {
                if (res && res.length > 0) {
                    let tt = res?.reduce((totalRate, item) => totalRate + parseInt(item.review_rate), 0);
                    let ratings = parseFloat(Math.round((tt / res.length) * 100) / 100).toFixed(1);
                    setReviewOverview({ list: res, rating: ratings });
                } else setReviewOverview({ list: [], rating: 0 });
            })
            .catch((err) => {
                showGeneralError(err);
            });
        }
    }, [productNo]);

    if (isLoading) {
        return <BlockLoader />;
    }

    const breadcrumb = [
        { title: "Home", url: url.home() },
        { title: "Shop", url: url.catalog() },
        { title: product.name, url: url.product(product) },
    ];

    let content;

    if (layout === "sidebar") {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">
                        <WidgetProducts title="Latest Products" products={latestProducts} />
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === "start" && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Product product={product} layout={layout} reviews={reviewOverview} />
                            <ProductTabs withSidebar reviews={reviewOverview} />
                        </div>

                        {relatedProducts.length > 0 && (
                            <BlockProductsCarousel title="Related Products" layout="grid-4-sm" products={relatedProducts} withSidebar />
                        )}
                    </div>
                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        <Product product={product} layout={layout} reviews={reviewOverview} />
                        <ProductTabs product={product} reviews={reviewOverview} />
                    </div>
                </div>

                {relatedProducts.length > 0 && (
                    <BlockProductsCarousel title="Related Products" layout="grid-5" products={relatedProducts} />
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${product.product_description} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={[]} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageProduct.defaultProps = {
    layout: "standard",
    sidebarPosition: "start",
};

export default ShopPageProduct;
