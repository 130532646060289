import { getHeader, paramBuilder } from "../services/utils";
const axios = require("axios");

export async function getPickUpStoreList(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/pickup_store_listing" + queryString;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getPickUpBufferDay(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/self_pickup_min_time" + queryString;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getPickUTimeRange(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/self_pickup_time_range" + queryString;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getStoreByDistance(data) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/count_store_distance";
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
