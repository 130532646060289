// react
import React from "react";

function ProductTabDescription(props) {
    return (
        <div className="typography">
            {props.desc?.trim() ? (
                <div style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: convertStringToLink(props.desc) }} />
            ) : (
                "No Description"
            )}
        </div>
    );
}

function convertStringToLink(value) {
    const urlRegex = /https?:\/\/|http?:\/\/|www.?[^\s]+/g;
    return value.replace(urlRegex, function (url) {
        let link = url;
        if (!url.includes("://")) {
            link = "//" + url;
        }
        return '<a href="' + link + '" target="_blank">' + url + "</a>";
    });
}

export default ProductTabDescription;
