import React, { useEffect, useState } from "react";
import { decodeChineseText, getNoImg } from "../../services/utils";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import BlockLoader from "../blocks/BlockLoader";
import { useIntl } from "react-intl";

function SitePagePromotion(props) {
    const intl = useIntl();
    const { location } = props;
    const promoState = location.state && location.state.promotion ? location.state.promotion : null;
    const [loading, setLoading] = useState(true);
    const [promotion, setPromotion] = useState(null);

    useEffect(() => {
        setPromotion(promoState);
        if (promoState) setLoading(false);
    }, [promoState]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${intl.formatMessage({ id: "promotion", defaultMessage: "Promotion" })} —  ${theme.name}`}</title>
            </Helmet>
            {loading ? (
                <div className="block-empty-page">
                    <BlockLoader />
                </div>
            ) : (
                <div className="block">
                    <div className={`container mt-4`}>
                        <h1>{decodeChineseText(promotion.banner_name)}</h1>
                        <div className="mb-3">
                            {promotion.banner_image?.[0] ? (
                                promotion.banner_image.map((item, index) => {
                                    return (
                                        <img
                                            key={"promo-image" + index}
                                            className="img-fluid w-100"
                                            // style={{ maxHeight: 500, maxWidth: 1800, objectFit: "cover" }}
                                            src={item}
                                            alt={"promo-image" + index}
                                            onError={(e) => (e.target.src = getNoImg())}
                                        />
                                    );
                                })
                            ) : (
                                <img
                                    className="img-fluid w-100"
                                    style={{ maxHeight: 500, maxWidth: 500, objectFit: "cover" }}
                                    src={getNoImg()}
                                    alt={"default-promo"}
                                />
                            )}
                        </div>
                        <p style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: decodeChineseText(promotion?.banner_desc?.toString())}} />
                        {promotion.banner_URL.map((link) => {
                            return (
                                <a key={link} target="_blank" rel="noreferrer" href={link}>
                                    {link}
                                </a>
                            );
                        })}
                        <p className="text-muted mt-3">
                            <i className="far fa-clock" /> {promotion.banner_start_date} - {promotion.banner_end_date}
                        </p>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default SitePagePromotion;
