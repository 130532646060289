import { FormattedMessage, useIntl } from "react-intl";

export const storeDistance = 30
export const languages = [
    {
        title: "English",
        locale: "en",
        code: "EN",
        icon: "images/languages/engFlat.png",
    },
    {
        title: "简体中文",
        locale: "zh",
        code: "ZH",
        icon: "images/languages/chiFlat.png",
    },
];

export const addressTypeList = [
    { code: "0", name: "Shipping" },
    { code: "1", name: "Billing" },
];

export const orderStatusList = [
    { code: "0", name: "Pending" },
    { code: "10", name: "Processing" },
    { code: "15", name: "Waiting Restock" },
    { code: "20", name: "Ready" },
    { code: "25", name: "Delivering" },
    { code: "30", name: "Completed" },
    { code: "130", name: "Cancelled" },
    { code: "100", name: "Pending Refund" },
    { code: "120", name: "Refunded" },
    { code: "110", name: "Refund Cancelled" },
    { code: "140", name: "Failed Payment" },
];

export const groupOrderStatusList = [
    { code: "Pending,Processing Payment", name: <FormattedMessage id="to_pay" defaultMessage="To Pay" /> },
    { code: "Paid,Delivering,Ready To Pickup", name: <FormattedMessage id="to_receive" defaultMessage="To Receive" /> },
    { code: "Waiting Comment", name: <FormattedMessage id="to_evaluate" defaultMessage="To Evaluate" /> },
];

export const paymentStatusList = [
    { code: "0", name: "Pending Payment" },
    { code: "10", name: "Paid" },
    { code: "20", name: "Payment Rejected" },
    { code: "30", name: "Payment Cancelled" },
];

export const voucherUsageTypeList = [
    { code: "0", name: "All" },
    { code: "1", name: "Member" },
    { code: "2", name: "Guest" },
];

export const locationTypeList = [
    { code: 0, name: "country" },
    { code: 1, name: "state" },
    { code: 2, name: "postcode" },
];

export const voucherTypes = [
    { code: "1", name: <FormattedMessage id="active" defaultMessage="Active" /> },
    { code: "2", name: <FormattedMessage id="expired_used" defaultMessage="Expired/Used" /> },
];

export const DELIVERY_METHOD = "DELIVERY";
export const PICKUP_METHOD = "PICKUP";
export const DELIVERY_N_PICKUP = "BOTHPD";

export const serviceMethod = [
    { title: <FormattedMessage id="modal_delivery" defaultMessage="Delivery" />, code: DELIVERY_METHOD },
    { title: <FormattedMessage id="pickup" defaultMessage="Pick Up" />, code: PICKUP_METHOD },
];

export const feedbackTypes = ["Staff attitude", "Mobile app", "Products", "Outlet", "Promotion", "Others"];

export const feedbackRate = [
    {
        title: "GOOD",
        iconClassname: "far fa-smile",
    },
    {
        title: "NEUTRAL",
        iconClassname: "far fa-meh",
    },
    {
        title: "POOR",
        iconClassname: "far fa-frown",
    },
];

//Use hardcode malaysia state instead of call api according to xs side
export const stateList = [
    "Johor",
    "Kedah",
    "Kelantan",
    "Kuala Lumpur",
    "Melaka",
    "Negeri Sembilan",
    "Pahang",
    "Penang",
    "Perak",
    "Perlis",
    "Sabah",
    "Sarawak",
    "Selangor",
    "Terengganu",
];

export const ProductTypeList = [
    { name: "Features Products", slug: "features", paramName: "homepage", paramField: "type" },
    { name: "Hottest Products", slug: "hottest", paramName: "bestseller", paramField: "type" },
    { name: "Latest Products", slug: "latest", paramName: "latest", paramField: "sort" },
];
