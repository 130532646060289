import { toast } from "react-toastify";
import { getMemberCode, removeUserFromStorage } from "../api/auth";
import React, { useState, useEffect } from "react";
import { sendSMS, verifyOTP } from "../api/general";
import { FormattedMessage } from "react-intl";

const CryptoJS = require("crypto-js");

const encryptPhrase = process.env.REACT_APP_ENCRYPT_PHRASE;
export const encryptWithAES = (str) => CryptoJS.AES.encrypt(str, encryptPhrase).toString();
export const decryptWithAES = (ciphertext) => {
    let originalText = "";
    try {
        const bytes = CryptoJS.AES.decrypt(ciphertext, encryptPhrase);
        originalText = bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        originalText = 0;
    }

    return originalText;
};

export const url = {
    home: () => "/",

    catalog: () => "/shop/catalog",

    brand: (brand) => `/brands/${encodeURIComponent(brand.brand_code)}`,

    category: (category) => `/category/${category.line_code}`,

    product: (product) => `/product/${product?.product_no}`,
};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}

export function getHeader() {
    return {
        Accept: "application/json",
        "Content-Type": "application/json",
        "XS-API-KEY": process.env.REACT_APP_XS_API_KEY,
    };
}

export function getDefaultAvatar() {
    return "/images/avatars/defaultAvatar.jpg";
}

export function getNoImg(size) {
    return "images/logos/hupsoon_food.png";
}

export function checkUnauthorized(err, history) {
    if (err.http_code == 401 || err.http_code == 403) {
        for (const errKey in err.data?.errors) {
            if (errKey.split(".")[0] == "token") {
                if (getMemberCode()) {
                    toast.error(
                        <FormattedMessage id="token_error_msg" defaultMessage="Sorry, your login session expired. Please try again." />
                    );
                    removeUserFromStorage();
                }
                break;
            }
        }
        return true;
    } else {
        return false;
    }
}

export function paramBuilder(rawParam) {
    let param = "";
    for (let key in rawParam) {
        if (rawParam[key]) {
            let symbol = param == "" ? "?" : "&";
            param = param + symbol + key + "=" + rawParam[key];
        }
    }
    return param;
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export function showGeneralError(err) {
    if (err && err.Error) {
        toast.error(err.Error);
    } else return toast.error(<FormattedMessage id="general_error_msg" defaultMessage="Oops, Something went wrong on server." />);
}

export function setWithExpiry(key, value, ttl) {
    const item = {
        value: value,
        expiry: new Date().getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

export function callEMS(params, msg) {
    let formData = new FormData();
    formData.append("user", params.ECOM_ESMS_USERNAME);
    formData.append("pass", params.ECOM_ESMS_PASSWORD);
    formData.append("to", params.mobileNumber);
    formData.append("msg", "RM0.00 - " + msg);
    sendSMS(formData)
        .then((result) => {})
        .catch((err) => {
            console.log(err);
        });
}

export async function verifyEMS_OTP(params) {
    let formData = new FormData();
    formData.append("api-key", params.ECOM_ESMS_USERNAME);
    formData.append("api-secret", params.ECOM_ESMS_PASSWORD);
    formData.append("phone", params.mobileNumber);
    formData.append("code", params.code);
    return await verifyOTP(formData);
}

export function convertToPrice(amount) {
    let price = amount?.match(/(\d+)/);
    return parseFloat(price[0]);
}

export function billRoundOff(amount) {
    let roundedVal = Math.round(parseFloat(amount) * 20) / 20;
    return parseFloat(roundedVal).toFixed(2);
}

export function convertAddressObjectToString(address) {
    return (
        address?.add_address1 +
        " " +
        address?.add_address2 +
        ", " +
        address?.add_postcode +
        ", " +
        address?.add_city +
        ", " +
        (address.add_state ? capitalizeFirstLetter(address.add_state) : "")
    );
}

export function decodeChineseText(value) {
    if (value) {
        return value.replace(/&#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec);
        });
    } else return value;
}

export function sortDefaultAddress(a, b) {
    if (a.add_default == 1) {
        return -1;
    }
    if (a.add_default == 0) {
        return 1;
    }
    return 0;
}

export const handleDisableDays = (date, pickupDaysRange) => {
    if (pickupDaysRange) {
        // api return days array format 0 to 6 is Monday to Sunday, while plugin is Sunday to Saturday
        // so need arrange to same format
        let daysRange = pickupDaysRange?.split(",");
        let last = daysRange.pop();
        daysRange.unshift(last);

        //return true if that day is disable to select
        let isDisable = daysRange[date.getDay()];
        return isDisable === "0";
    } else return null;
};

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function getFinalPrice(product) {
    if (parseFloat(product.final_price).toFixed(2) < parseFloat(product.product_price).toFixed(2)) {
        return product.final_price;
    } else return product.product_price;
}
