// react
import React, { useEffect, useState, Suspense, lazy } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import BlockLoader from "./blocks/BlockLoader";
import ScrollToTop from "./ScrollToTop";

// application
import Footer from "./footer";
import Header from "./header";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import Quickview from "./shared/Quickview";

// pages
import AccountLayout from "./account/AccountLayout";
import AccountPageLogin from "./account/AccountPageLogin";
import PageCart from "./shop/ShopPageCart";
import PageCheckout from "./shop/ShopPageCheckout";
import PageWishlist from "./shop/ShopPageWishlist";
import ShopPageCategory from "./shop/ShopPageCategory";
import ShopPageOrderSuccess from "./shop/ShopPageOrderSuccess";
import ShopPageProduct from "./shop/ShopPageProduct";
import SitePageAboutUs from "./site/SitePageAboutUs";
import SitePageContactUs from "./site/SitePageContactUs";
import SitePageNotFound from "./site/SitePageNotFound";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../services/ErrorFallback.js";
import theme from "../data/theme";

import MobileUserMenu from "./mobile/MobileUserMenu";
import { getCompanyContact } from "../api/general";
import { getMemberCode } from "../api/auth";
import { getProductGroupCategories } from "../api/product";
import SitePagePromotion from "./site/SitePagePromotion";
import ShopPageSearchProduct from "./shop/ShopPageSearchProduct";
import ShopPageByCategory from "./shop/ShopPageByCategory";
import store from "../store";
import { openDeliveryModal, updateNotification, updateUser } from "../store/user";
import { syncWishlist } from "../store/wishlist";
import DeliveryMethodModal from "./shared/DeliveryMethodModal";
import { getMyCart } from "../api/cart";
import { getFavoriteProducts } from "../api/favorite";
import { syncMyCart } from "../store/cart";
import ShopPageProductList from "./shop/ShopPageProductList";
import { storeGroupCategory } from "../store/generalInfo";
import { useSelector } from "react-redux";

const AccountPageRegister = lazy(() => import("./account/AccountPageRegister"));
const SitePageOutlets = lazy(() => import("./site/SitePageOutlets"));
const SitePageForgotPassword = lazy(() => import("./site/SitePageForgotPassword"));
const SitePageResetPassword = lazy(() => import("./site/SitePageResetPassword"));
const SitePagePrivacyPolicy = lazy(() => import("./site/SitePagePrivacyPolicy"));
const SitePageMemberZone = lazy(() => import("./site/SitePageMemberZone"));
function Layout(props) {
    const { match, headerLayout, homeComponent } = props;
    const [companyContact, setCompanyContact] = useState(null);
    const token = getMemberCode();
    let deliveryMethod = useSelector((state) => state?.user?.deliveryMethod);

    useEffect(() => {
        if (!token) {
            store.dispatch(updateUser(null));
            store.dispatch(updateNotification(0));
            store.dispatch(syncWishlist([]));
            // store.dispatch(syncMyCart([]));
        } else {
            getMyCart();
            getFavoriteProducts();
            if (
                !localStorage.getItem("shipping") ||
                !JSON.parse(localStorage.getItem("shipping")) ||
                !deliveryMethod.type ||
                !deliveryMethod.store ||
                !deliveryMethod.address.add_state
            ) {
                store.dispatch(openDeliveryModal(true));
            }
        }
    }, [token]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        getProductGroupCategories().then((res) => {
            store.dispatch(storeGroupCategory(res));
        });
        getCompanyContact().then((res) => {
            if (res) {
                setCompanyContact(res);
            }
        });
    }, []);

    const handleScroll = () => {
        if (document.getElementById("mobile-sticky")) {
            if (window.pageYOffset > 100) {
                document.getElementById("mobile-sticky").classList.add("fixed");
            } else {
                document.getElementById("mobile-sticky").classList.remove("fixed");
            }
        }
    };

    // page use gray bg instead of white
    const checkPageReturnClass = (pathname) => {
        if (
            pathname.indexOf("/voucher-details/") > -1 ||
            pathname == "/cart" ||
            pathname == "/checkout" ||
            pathname == "/login" ||
            pathname == "/register" ||
            pathname == "/resend-verification" ||
            pathname == "/forgot-password"
        )
            return "site__body grey";
        else return "site__body";
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
            </Helmet>

            <ToastContainer autoClose={5000} theme="colored" newestOnTop hideProgressBar />

            <Quickview />

            <DeliveryMethodModal />

            <MobileMenu />

            <MobileUserMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header sticky-header d-lg-block d-none">
                    <Header />
                </header>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense
                        fallback={
                            <div className="block-empty-page">
                                <BlockLoader />
                            </div>
                        }
                    >
                        <div id="site-body" className={checkPageReturnClass(window.location.pathname)}>
                            <ScrollToTop>
                                <Switch>
                                    {/*
                                // Home
                                */}
                                    <Route exact path={`${match.path}`} component={homeComponent} />
                                    {/*
                                // Shop
                                */}
                                    <Redirect exact from="/shop" to="/shop/catalog" />
                                    <Route
                                        exact
                                        path="/shop/catalog"
                                        render={(props) => (
                                            <ShopPageCategory {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/category/:code"
                                        render={(props) => (
                                            <ShopPageByCategory
                                                {...props}
                                                columns={4}
                                                viewMode="grid"
                                                sidebarPosition="start"
                                                lineName={props.location.state?.lineName ?? ""}
                                                groupCode={new URLSearchParams(props.location.search).get("group_code") ?? ""}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/category"
                                        render={(props) => (
                                            <ShopPageCategory {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                                        )}
                                    />
                                    <Route
                                        path="/search-products"
                                        render={(props) => (
                                            <ShopPageSearchProduct
                                                {...props}
                                                columns={5}
                                                viewMode="grid"
                                                sidebarPosition="start"
                                                searchValue={decodeURI(
                                                    props.location?.search ? props.location.search.substring(1)?.split("=")[1] : ""
                                                )}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/product-list/:slug"
                                        render={(props) => (
                                            <ShopPageProductList
                                                {...props}
                                                slug={props.match.params.slug}
                                                columns={5}
                                                viewMode="grid"
                                                sidebarPosition="start"
                                            />
                                        )}
                                    />

                                    <Route
                                        exact
                                        path="/product/:productNo"
                                        render={(props) => (
                                            <ShopPageProduct {...props} layout="standard" productNo={props.match.params.productNo} />
                                        )}
                                    />

                                    <Route exact path="/cart" component={PageCart} />
                                    <Route exact path="/checkout" component={PageCheckout} />
                                    <Route exact path="/order-success" component={ShopPageOrderSuccess} />
                                    <Route exact path="/favourites" component={PageWishlist} />

                                    {token
                                        ? null
                                        : [
                                              <Route key="register" exact path="/register" component={AccountPageRegister} />,
                                              <Route key="login" exact path="/login" render={(props) => <AccountPageLogin {...props} />} />,
                                          ]}
                                    <Route path="/account" component={AccountLayout} />
                                    <Route exact path="/forgot-password" component={SitePageForgotPassword} />
                                    <Route exact path="/password-reset" component={SitePageResetPassword} />
                                    <Route exact path="/about-us" component={SitePageAboutUs} />
                                    <Route exact path="/privacy-policy" component={SitePagePrivacyPolicy} />
                                    {/* <Route exact path="/contact-us" component={SitePageContactUs} /> */}
                                    <Route exact path="/member-zone" component={SitePageMemberZone} />
                                    <Route exact path="/outlets" component={SitePageOutlets} />
                                    <Route exact path="/promotion/:id" component={SitePagePromotion} />

                                    {/*
                                // Page Not Found
                                */}
                                    <Route component={SitePageNotFound} />
                                </Switch>
                            </ScrollToTop>
                        </div>
                    </Suspense>
                </ErrorBoundary>
                <footer className="site__footer">
                    <Footer companyContact={companyContact} />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(["default", "compact"]),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: "default",
};

export default Layout;
