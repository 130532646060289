// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";

function convertThousandNumbers(num) {
    let amount = parseFloat(num).toFixed(2);
    let formatted = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formatted;
}
function Currency(props) {
    const { value, currency, currentCurrency } = props;
    const { symbol } = currency || currentCurrency;
    let price = parseFloat(value ? value : 0).toFixed(2);
    return <React.Fragment>{`${symbol} ${convertThousandNumbers(price)}`}</React.Fragment>;
}

Currency.propTypes = {
    /** currency object, specify to override currentCurrency */
    currency: PropTypes.object,
    /** currency object */
    currentCurrency: PropTypes.object,
};

const mapStateToProps = (state) => ({
    currentCurrency: state.currency,
});

export default connect(mapStateToProps)(Currency);
