import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM, SYNC_WISHLIST } from "./wishlistActionTypes";

function addItem(state, product) {
    const itemIndex = state.findIndex((x) => x.product_code === product.product_code);

    if (itemIndex === -1) {
        return [...state, JSON.parse(JSON.stringify(product))];
    }

    return state;
}

const initialState = [];

export default function wishlistReducer(state = initialState, action) {
    switch (action.type) {
        case WISHLIST_ADD_ITEM:
            return addItem(state, action.product);

        case WISHLIST_REMOVE_ITEM:
            return state.filter((x) => x.product_code !== action.product.product_code);

        case SYNC_WISHLIST:
            return action.products;

        default:
            return state;
    }
}
