import { STORE_GENERAL_INFO, STORE_COUNTRIES, STORE_SYSTEM_INFO, STORE_GROUP_CATEGORY } from "./generalInfoTypes";

const initialState = {
    info: null,
    system: null,
    countries: [],
    categories: [],
};

export default function generalInfoReducer(state = initialState, action) {
    if (action.type === STORE_GENERAL_INFO) {
        return { ...state, info: action.generalInfo };
    } else if (action.type === STORE_SYSTEM_INFO) {
        return { ...state, system: action.systemInfo };
    } else if (action.type === STORE_COUNTRIES) {
        return { ...state, countries: action.countries };
    } else if (action.type === STORE_GROUP_CATEGORY) {
        return { ...state, categories: action.categories };
    }
    return state;
}
