// react
import React, { useEffect, useReducer, useState } from "react";

// third-party
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";

// application
import BlockLoader from "../blocks/BlockLoader";
import CategorySidebar from "./CategorySidebar";
import CategorySidebarItem from "./CategorySidebarItem";
import PageHeader from "../shared/PageHeader";
import { sidebarOpen, sidebarClose } from "../../store/sidebar";
import { useHistory } from "react-router-dom";

// data stubs
import theme from "../../data/theme";
import { url, getNoImg, decodeChineseText } from "../../services/utils";
import { getProductGroupCategories } from "../../api/product";
import { Link } from "react-router-dom";
import { Filters16Svg } from "../../svg";
import classNames from "classnames";
const perPage = 25;
let prevParentCategoryIndex = 0;

function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.page === "string") {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === "string") {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === "string") {
        optionValues.sort = query.sort;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [parseQueryOptions(location), parseQueryFilters(location)];
}

function buildQuery(options, filters) {
    const params = {};

    if (options.page !== 1) {
        params.page = options.page;
    }

    if (options.limit !== 12) {
        params.limit = options.limit;
    }

    if (options.sort !== "default") {
        params.sort = options.sort;
    }

    Object.keys(filters)
        .filter((x) => x !== "category" && !!filters[x])
        .forEach((filterSlug) => {
            params[`filter_${filterSlug}`] = filters[filterSlug];
        });

    return queryString.stringify(params, { encode: false });
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    categoryIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {
        sort: "product_topsales",
        limit: "0," + perPage,
    },
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

function reducer(state, action) {
    switch (action.type) {
        case "FETCH_CATEGORY_SUCCESS":
            return {
                ...state,
                init: true,
                categoryIsLoading: false,
                category: action.category,
            };
        case "FETCH_PRODUCTS_LIST":
            return { ...state, productsListIsLoading: true };
        case "FETCH_PRODUCTS_LIST_SUCCESS":
            return { ...state, productsListIsLoading: false, productsList: action.productsList };
        case "SET_OPTION_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1, [action.option]: action.value },
            };
        case "SET_FILTER_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1 },
                filters: { ...state.filters, [action.filter]: action.value },
            };
        case "RESET_FILTERS":
            return { ...state, options: { ...state.options, page: 1 }, filters: {} };
        case "RESET":
            return state.init ? initialState : state;
        default:
            throw new Error();
    }
}

function init(state) {
    const [options, filters] = parseQuery(window.location.search);

    return { ...state, options, filters };
}

function ShopPageCategory(props) {
    const intl = useIntl();
    const { columns, viewMode, sidebarPosition } = props;
    const offcanvas = columns === 3 ? "mobile" : "always";
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const dispatchThis = useDispatch();
    const history = useHistory();
    const [filterParams, setFilterParams] = useState({
        // limit: "0,24",
    });
    const [doneLoad, setDoneLoad] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(0);

    // Replace current url.
    useEffect(() => {
        const query = buildQuery(state.options, state.filters);
        const location = `${window.location.pathname}${query ? "?" : ""}${query}`;

        window.history.replaceState(null, "", location);
    }, [state.options, state.filters]);

    useEffect(() => {
        getProductGroupCategories().then((res) => {
            dispatch({ type: "FETCH_CATEGORY_SUCCESS", category: res });
        });
    }, []);

    useEffect(() => {
        if (!state.categoryIsLoading) {
            // implement back to previous product when detect is back from product details
            window.onpopstate = (e) => {
                let prevCategory = localStorage.getItem("parentCategory");
                if (prevCategory && state.category[prevCategory]) {
                    prevParentCategoryIndex = prevCategory;
                    setTimeout(() => localStorage.removeItem("parentCategory"), 800);
                    setSelectedCategory(prevCategory);
                } else {
                    prevParentCategoryIndex = 0;
                }
            };
        }

        return () => {
            if (!history.location.pathname.includes("/category")) {
                prevParentCategoryIndex = 0;
            }
        }
    }, [state.categoryIsLoading]);

    if (state.categoryIsLoading) {
        return <BlockLoader />;
    }

    let pageTitle = <FormattedMessage id="category" defaultMessage="Category" />;
    let content;

    const setParentCategory = () => {
        localStorage.setItem("parentCategory", prevParentCategoryIndex ?? selectedCategory);
    };

    const categoryView = state.category[prevParentCategoryIndex ?? selectedCategory]?.line?.map((cat, index) => {
        let path = {
            pathname: url.category(cat),
            state: { lineName: cat.line_name },
            search: "?group_code=" + state.category[prevParentCategoryIndex ?? selectedCategory].group_code,
        };

        return (
            <div key={cat.line_code} className="category-box">
                <div className="img-wrapper">
                    <Link to={path} onClick={() => setParentCategory()}>
                        <img
                            src={cat.line_icon?.[0] ? cat.line_icon : getNoImg()}
                            onError={(e) => (e.target.src = getNoImg())}
                            alt={cat.line_name}
                        />
                    </Link>
                </div>
                <div className="item-detail">
                    <Link to={path} onClick={() => setParentCategory()}>
                        {decodeChineseText(cat.line_name)}
                    </Link>
                </div>
            </div>
        );
    });

    const handleCategoryChange = (index) => {
        prevParentCategoryIndex = index;
        setSelectedCategory(index);
        dispatchThis(sidebarClose());
    };

    const categoryListSection = () => {
        return (
            <div>
                <div className="filter--opened">
                    <div className="filter filter--opened">
                        <div className="filter__body">
                            <div className="filter__container">
                                {state.category.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                (prevParentCategoryIndex ?? selectedCategory) == index
                                                    ? "filter-list__item active"
                                                    : "filter-list__item"
                                            }
                                            onClick={() => handleCategoryChange(index)}
                                        >
                                            {decodeChineseText(item.group_name)}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const sidebarComponent = (
        <CategorySidebar offcanvas={offcanvas}>
            <CategorySidebarItem>
                <div className="widget-filters widget widget-filters--offcanvas--mobile">
                    {state.category && state.category.length > 0 ? categoryListSection() : null}
                </div>
            </CategorySidebarItem>
        </CategorySidebar>
    );

    const viewOptionsClasses = classNames("view-options", {
        "view-options--offcanvas--always": offcanvas === "always",
        "view-options--offcanvas--mobile": offcanvas === "mobile",
    });

    const filterBtn = (
        <div className={viewOptionsClasses}>
            <div className="view-options__filters-button mb-3">
                <button type="button" className="filters-button" onClick={() => dispatchThis(sidebarOpen())}>
                    <Filters16Svg className="filters-button__icon" />
                    <span className="filters-button__title">
                        <FormattedMessage id="filter" defaultMessage="Filters" />
                    </span>
                </button>
            </div>
        </div>
    );

    if (columns > 3) {
        content = (
            <div className="container">
                {filterBtn}
                <div className="block row no-gutters pl-0 pr-0">{categoryView}</div>
                {sidebarComponent}
            </div>
        );
    } else {
        const sidebar = <div className="shop-layout__sidebar">{sidebarComponent}</div>;

        content = (
            <div className="container">
                {filterBtn}
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === "start" && sidebar}
                    <div className="shop-layout__content vproduct-section-3 grid-3">
                        <div className="block row no-gutters pl-0 pr-0">{categoryView}</div>
                    </div>
                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${intl.formatMessage({ id: "category", defaultMessage: "Shop Category Page" })} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header={pageTitle} breadcrumb={[]} />
            <div className="container">
                {state.category.find((item, index) => index == prevParentCategoryIndex ?? selectedCategory) && (
                    <h4>
                        {decodeChineseText(
                            state.category.find((item, index) => index == prevParentCategoryIndex ?? selectedCategory).group_name
                        )}
                    </h4>
                )}
            </div>
            {content}
        </React.Fragment>
    );
}

ShopPageCategory.propTypes = {
    /**
     * Category slug.
     */
    categoryCode: PropTypes.string,
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageCategory.defaultProps = {
    columns: 3,
    viewMode: "grid",
    sidebarPosition: "start",
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
});

const mapDispatchToProps = () => ({
    sidebarClose,
    sidebarOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory);
