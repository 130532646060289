// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import Indicator from "./Indicator";
import { Cart20Svg, Cross10Svg } from "../../svg";
import { cartRemoveItem } from "../../store/cart";
import { getNoImg, url } from "../../services/utils";

function IndicatorCart(props) {
    const { cart, cartRemoveItem } = props;
    let dropdown;
    let totals;

    // if (cart.extraLines.length > 0) {
    //     const extraLines = cart.extraLines.map((extraLine, index) => (
    //         <tr key={index}>
    //             <th>{extraLine.title}</th>
    //             <td><Currency value={extraLine.price} /></td>
    //         </tr>
    //     ));

    //     totals = (
    //         <React.Fragment>
    //             <tr>
    //                 <th>Subtotal</th>
    //                 <td><Currency value={cart.subtotal} /></td>
    //             </tr>
    //             {extraLines}
    //         </React.Fragment>
    //     );
    // }

    const items = cart?.items?.map((item) => {
        let options;
        let image;

        image = (
            <div className="product-image dropcart__product-image">
                <Link to={url.product(item.product)} className="product-image__body">
                    <img
                        className="product-image__img"
                        src={item.product_picture ? item.product_picture : getNoImg()}
                        onError={(e) => (e.target.src = getNoImg())}
                        alt=""
                    />
                </Link>
            </div>
        );

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id)}
                render={({ run, loading }) => {
                    const classes = classNames("dropcart__product-remove btn btn-light btn-sm btn-svg-icon", {
                        "btn-loading": loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.product_code} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={url.product(item)}>{item.product_name}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{parseInt(item.product_quantity)}</span>
                        {" × "}
                        <span className="dropcart__product-price">
                            <Currency value={item.product_price} />
                        </span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    // if (cart?.quantity) {
    //     dropdown = (
    //         <div className="dropcart">
    //             <div className="dropcart__products-list">{items}</div>

    //             <div className="dropcart__totals">
    //                 <table>
    //                     <tbody>
    //                         {totals}
    //                         <tr>
    //                             <th>Total</th>
    //                             <td>
    //                                 <Currency value={cart.total} />
    //                             </td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //             </div>

    //             <div className="dropcart__buttons">
    //                 <Link className="btn btn-secondary" to="/shop/cart">
    //                     View Cart
    //                 </Link>
    //                 <Link className="btn btn-primary" to="/shop/checkout">
    //                     Checkout
    //                 </Link>
    //             </div>
    //         </div>
    //     );
    // } else {
    //     dropdown = (
    //         <div className="dropcart">
    //             <div className="dropcart__empty">Your shopping cart is empty!</div>
    //         </div>
    //     );
    // }

    return <Indicator url="/cart" value={cart?.quantity} icon={<Cart20Svg />} />;
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
